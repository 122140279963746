
import { createStore } from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleLayout from "./modules/layout.js"
import moduleUsers from './modules/users.js'
import moduleNotifications from "./modules/notifications.js"
import moduleCaseCategory from './modules/case-categories.js'
import moduleCaseStatus from './modules/case-statuses.js'
import moduleCourtCategory from './modules/court-categories.js'
import moduleDepartment from './modules/departments.js'
import moduleJudge from './modules/judges.js'
import moduleCourt from './modules/courts.js'
import moduleCase from './modules/cases.js'
import moduleStage from './modules/stages.js'
import moduleDuty from './modules/duties.js'
import moduleQuery from './modules/queries.js'

const store = createStore({
    state,
    actions,
    getters,
    mutations,
    modules: {
        layout: moduleLayout,
        userList: moduleUsers,
        notification: moduleNotifications,
        courtCategory: moduleCourtCategory,
        caseCategory: moduleCaseCategory,
        department: moduleDepartment,
        judge: moduleJudge,
        court: moduleCourt,
        case: moduleCase,
        stage: moduleStage,
        caseStatus: moduleCaseStatus,
        duty: moduleDuty,
        query: moduleQuery
    },
    strict: process.env.NODE_ENV !== 'production',
})

export default store
