export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '/main',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                  title: 'Dashboard',
                  authRequired: true,
                }
            },
            {
                path: '/account',
                name: 'account',
                component: () => import('@views/account/UserEdit.vue'),
                meta: {
                  title: 'Account',
                  authRequired: true,
                },
            },
            {
              path: '/case-categories',
              name: 'case-categories',
              component: () => import('@views/cases/Categories.vue'),
              meta: {
                title: "case-categories",
                authRequired: true,
              }
            },
            {
              path: '/case-statuses',
              name: 'case-statuses',
              component: () => import('@views/cases/CaseStatuses.vue'),
              meta: {
                title: "case-statuses",
                authRequired: true,
              }
            },
            {
              path: '/court-categories',
              name: 'court-categories',
              component: () => import('@views/courts/Categories.vue'),
              meta: {
                title: "court-categories",
                authRequired: true,
              }
            },
            {
              path: '/departments',
              name: 'departments',
              component: () => import('@views/departments/Departments.vue'),
              meta: {
                title: "Departments",
                authRequired: true,
              }
            },
            {
              path: '/departments/create',
              name: 'department-create',
              component: () => import('@views/departments/DepartmentCreate.vue'),
              meta: {
                title: "Create New Department",
                authRequired: true,
              }
            },
            {
              path: '/departments/:departmentId(\\d+)',
              name: 'department-show',
              component: () => import('@views/departments/DepartmentShow.vue'),
              meta: {
                title: "View Department",
                authRequired: true,
              },
            },
            {
              path: '/judges',
              name: 'judges',
              component: () => import('@views/judges/Judges.vue'),
              meta: {
                title: "Judges",
                authRequired: true,
              }
            },
            {
              path: '/courts/create',
              name: 'court-create',
              component: () => import('@views/courts/CourtCreate.vue'),
              meta: {
                title: "Add New Court",
                authRequired: true,
              }
            },
            {
              path: '/courts',
              name: 'courts',
              component: () => import('@views/courts/CourtList.vue'),
              meta: {
                title: "Courts",
                authRequired: true,
              }
            },
            {
              path: '/courts/:courtId(\\d+)',
              name: 'court-show',
              component: () => import('@views/courts/CourtShow.vue'),
              meta: {
                title: "View Court",
                authRequired: true,
              },
            },
            {
              path: '/cases',
              name: 'cases',
              component: () => import('@views/cases/CaseList.vue'),
              meta: {
                title: "Cases",
                authRequired: true,
              }
            },
            {
              path: '/cases/:caseId(\\d+)',
              name: 'case-show',
              component: () => import('@views/cases/CaseShow.vue'),
              meta: {
                title: "View Case",
                authRequired: true,
              },
            },
            {
                path: '/users',
                name: 'users-list',
                component: () => import('@views/users/UserList.vue'),
                meta: {
                  title: "Users List",
                  authRequired: true,
                },
            },
            {
                path: '/users/create',
                name: 'users-create',
                component: () => import('@views/users/UserCreate.vue'),
                meta: {
                  title: "Create User",
                  authRequired: true,
                },
            },
            {
              path: '/users/:userId(\\d+)',
              name: 'user-view',
              component: () => import('@views/users/UserShow.vue'),
              meta: {
                title: "View User",
                authRequired: true,
              },
            },
            {
              path: '/stages',
              name: 'stages',
              component: () => import('@views/stages/Stages.vue'),
              meta: {
                title: "Stages",
                authRequired: true,
              }
            },
            {
              path: '/duties',
              name: 'duties',
              component: () => import('@views/duties/Duties.vue'),
              meta: {
                title: "Duties",
                authRequired: true,
              }
            },
            {
              path: '/notifications',
              name: 'notifications',
              component: () => import('@views/notifications/NotificationList.vue'),
              meta: {
                title: "Notifications",
                authRequired: true,
              }
            },
            {
              path: '/audit-trails',
              name: 'audit-trails',
              component: () => import('@views/audit-trails/AuditTrails.vue'),
              meta: {
                title: "Audit Trails",
                authRequired: true,
              }
            },
            {
              path: '/attendances',
              name: 'attendances',
              component: () => import('@views/attendances/Attendances.vue'),
              meta: {
                title: "Attendance List",
                authRequired: true,
              }
            },
            {
              path: '/queries',
              name: 'queries',
              component: () => import('@views/queries/Queries.vue'),
              meta: {
                title: "Query List",
                authRequired: true,
              }
            },
            {
              path: '/queries/:queryId(\\d+)',
              name: 'query-show',
              component: () => import('@views/queries/QueryShow.vue'),
              meta: {
                title: "View Query",
                authRequired: true,
              },
            },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/pages/Error404.vue'),
            meta: {
              title: "404 - Page not Found"
            }
          },
          {
            path: '/error-500',
            name: 'error-500',
            component: () => import('@/views/pages/Error500.vue'),
            meta: {
              title: "500 - Server error"
            }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]