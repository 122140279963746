import env from '../env.js'
import { getUserData } from '@/helpers/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
        shortDesc: "Case Management System."
    },
    windowWidth: null,
    countries: [],
    localGovernments: [],
    dashboardData:{
        "cases": {
          "total": 0,
          "today": 0,
          "pending": 0,
          "closed": 0,
          "resolved": 0,
          "dismissed": 0,
          "settled": 0,
          "appealed": 0,
        },
        "users": {
          "total": 0,
          "lawyer": 0,
          "head": 0,
          "admin": 0
        },
        "departments": 0
    },
}

export default state
